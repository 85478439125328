<template>
    <div>
      <!-- TODO: VP-14 CREAR COMPONENTE PARA LA TABLA EN MOBILE -->
        <TableGroup
            id="request"
            :items="requestList"
            :isLoading="isTableLoading"
            :pagination="pagination"
            :searchText="filters.searchText"
            :showExportButton="true"
            @handleSearch="handleSearchByInput"
            @handleSearchByFilter="handleSearchByFilter"
            @handlePaginate="handleFilterSearch"
            @handleCleanSearch="handleCleanSearch"
            @handleExport="handleShowExportModal"
            >
            <template v-slot:content>
              <RequestFilters
                @handleChangeCreatedAt="handleChangeCreatedAt"
                @handleChangeOrigin="handleChangeOrigin"
                @handleChangeSpecialty="handleChangeSpecialty"
                @handleChangeClinic="handleChangeClinic"
                @handleChangeStage="handleChangeStage"
                @handleChangeStatus="handleChangeStatus"
                :filters="filters"
              />
            </template>

            <RequestTable
                ref="requestTable"
                :requestItems="requestList"
                :canDelete="!isDoctor && !isCollaborator"
                :canEdit="!isDoctorRole"
                :canCopy="!isDoctorOrClinic && !isCollaborator"
                :isFormShowed="showDetail"
                :showActions="!isDoctor"
                @showUpdateForm="showUpdateForm"
                @showCopyForm="showCopyForm"
                @showDeleteModal="showDeleteModal"
                @handleSelectRow="handleSelectRow"
                @handleSortAsc="handleSortAsc"
                @handleSortDesc="handleSortDesc"
            />
        </TableGroup>
        <!-- MODALS START -->
        <!-- VP-14: CREATE MOBILE VIEW FOR EACH MODAL AND SET THE IS MOBILE LOGIC HERE. -->
        <RequestDetail
          id="request-detail"
          :request="requestData"
          @handleChangeRequest="handleChangeRequest"
          @handleCancel="closeDetail"
        />
        <UpdateRequest
          id="update-request"
          @updateRequest="handleUpdateRequest"
          :request="requestData"
        />
        <CopyRequest
          id="copy-request"
          @copyRequest="handleCopyRequest"
          @handleCancel="handleRevertEditedRowRequest"
          :request="requestData"
        />
        <DeleteRequest
          @deleteRequest="handleDeleteRequest"
          ref="deleteRequest"
        />
        <!-- MODALS END -->
    </div>
</template>
<script>
import { exportRequests } from '@/store/request/requestActions';
import {
  getSortType,
  SORT_TYPE,
  STATUS,
} from '@/helpers/ComponentHelper';
import { getOrdenation, getFiltersGroup } from '@/helpers/ApiHelper';

export default {
  props: {},
  data() {
    return {
      requestData: {},
      sort: {
        name: 'createdAt',
        type: 'desc',
      },
      filters: {
        createdAt: null,
        origin: null,
        specialty: null,
        clinic: null,
        stage: null,
        status: null,
        searchText: null,
      },
      showDetail: false,
      isTableLoading: false,
      oldEditedRow: {
        id: null,
        notes: [],
        documents: [],
      },
    };
  },
  created() {},
  mounted() {
    this.handleSearch();
  },
  watch: {
    request(newValue) {
      this.request = newValue;
    },
  },
  methods: {
    handleSearch(page = 1) {
      this.isTableLoading = true;

      this.$store.dispatch('request/getAllRequest', page)
        .then(() => {
          // this.requestList = res.requestList;
          // this.pagination = res.pagination;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },
    handleFilterSearch(page = 1) {
      this.isTableLoading = true;

      this.$store.dispatch('request/filterRequest', this.getRequestData(page))
        .then(() => {
          // this.requestList = res.requestList;
          // this.pagination = res.pagination;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },
    getRequestData(page = 1) {
      return {
        page,
        perPage: 10,
        filters: this.getFilters(),
        ordenation: getOrdenation(this.sort),
      };
    },
    getFilters() {
      const filtersGroup = [];

      if (this.filters.searchText !== null) {
        if (!this.checkText()) {
          filtersGroup.push(
            `name:${this.filters.searchText}`,
          );
        } else {
          filtersGroup.push(
            `caseNumber:${this.filters.searchText}`,
          );
        }
      }

      return getFiltersGroup(filtersGroup, this.filters);
    },
    handleSearchByInput(text) {
      if (text !== null) {
        this.filters.searchText = text;
        this.handleFilterSearch();
      } else {
        this.filters.searchText = null;
      }
    },
    handleSearchByFilter() {
      this.handleFilterSearch();
      this.$root.$emit('bv::toggle::collapse', 'request-sidebar');
    },
    handleCleanSearch() {
      this.filters.createdAt = null;
      this.filters.origin = null;
      this.filters.specialty = null;
      this.filters.clinic = null;
      this.filters.stage = null;
      this.filters.status = null;
      this.filters.searchText = null;
      this.sort.name = null;
      this.sort.type = null;

      this.handleSearch();
    },
    handleShowExportModal() {
      this.isTableLoading = true;

      this.$store.dispatch('request/exportRequests', null)
        .then((res) => {
          this.downloadRequestCsv(res);
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },
    downloadRequestCsv(document) {
      exportRequests(document);
    },
    handleChangeCreatedAt(value) {
      this.filters.createdAt = value;
    },
    handleChangeOrigin(value) {
      this.filters.origin = value;
    },
    handleChangeSpecialty(value) {
      this.filters.specialty = value;
    },
    handleChangeClinic(value) {
      this.filters.clinic = value;
    },
    handleChangeStage(value) {
      this.filters.stage = value;
    },
    handleChangeStatus(value) {
      this.filters.status = value;
    },
    showUpdateForm(data) {
      this.setRequest(data);
      this.$root.$emit('bv::toggle::collapse', 'update-request-sidebar');
    },
    showCopyForm(data) {
      this.oldEditedRow.id = data.id;
      for (let i = 0; i < data.documents.length; i += 1) {
        const newOldDocuments = data.documents[i];
        this.oldEditedRow.documents.push(newOldDocuments);
      }

      for (let i = 0; i < data.notes.length; i += 1) {
        this.oldEditedRow.notes.push(data.notes[i]);
      }
      this.setRequest({ ...data, createdAt: new Date().toDateString() }, false);

      this.$root.$emit('bv::toggle::collapse', 'copy-request-sidebar');
    },
    showDeleteModal(data) {
      this.$refs.deleteRequest.setId(data.id);
      this.$bvModal.show('delete-request');
    },
    showDetailView() {
      this.showDetail = true;
    },
    handleSelectRow(item) {
      this.setRequest(item);
      this.$root.$emit('bv::toggle::collapse', 'request-detail-sidebar');
    },
    handleSortAsc(param) {
      this.sort.type = this.sort.name === param ? getSortType(this.sort.type) : SORT_TYPE.ASC;
      this.sort.name = param;

      this.handleFilterSearch();
    },
    handleSortDesc(param) {
      this.sortDesc = this.sort.name === param ? getSortType(this.sort.type) : SORT_TYPE.DESC;
      this.handleSearch();
    },
    handleRevertEditedRowRequest() {
      for (let i = 0; i < this.requestList.length; i += 1) {
        if (this.requestList[i].id === this.oldEditedRow.id) {
          this.requestList[i].notes = this.oldEditedRow.notes;
          this.requestList[i].documents = this.oldEditedRow.documents;

          break;
        }
      }

      this.oldEditedRow.id = null;
      this.oldEditedRow.notes = [];
      this.oldEditedRow.documents = [];
    },
    closeDetail() {
      this.hideDetailView();
    //   this.$refs.requestTable.clearSelected();
    },
    hideDetailView() {
      this.showDetail = false;
    },
    handleChangeRequest(id, status) {
      if (status === STATUS.ACCEPTED.key) {
        this.changeRequestStatus(id, status, true);
      } else {
        this.changeRequestStatus(id, status, false);
      }

      this.hideDetailView();
      this.$root.$emit('bv::toggle::collapse', 'request-detail-sidebar');
    },
    changeRequestStatus(id, status, isAccepted) {
      for (let i = 0; i < this.requestList.length; i += 1) {
        if (this.requestList[i].id === id) {
          const newRequest = {
            id: this.requestList[i].id,
            clinic: this.requestList[i].clinic,
            createdAt: this.requestList[i].createdAt,
            documents: this.requestList[i].documents,
            level: this.requestList[i].level,
            healthManager: this.requestList[i].healthManager,
            caseNumber: this.requestList[i].caseNumber,
            hasInsurance: this.requestList[i].hasInsurance,
            medicalProcedure: this.requestList[i].medicalProcedure,
            notes: this.requestList[i].notes,
            appointments: this.requestList[i].appointments,
            pathology: this.requestList[i].pathology,
            patient: this.requestList[i].patient,
            hasRecentReports: this.requestList[i].hasRecentReports,
            specialty: this.requestList[i].specialty,
            requestStage: this.requestList[i].requestStage,
            collaborators: this.requestList[i].collaborators,
            invitations: this.requestList[i].invitations,
            status,
            owner: this.requestList[i].owner,
            accepted: isAccepted,
          };

          this.requestList.splice(i, 1);
          this.requestList.splice(i, 0, newRequest);

          break;
        }
      }
    },
    setRequest(data, hasClinic = true) {
      this.requestData = this.getNewRequest(data, hasClinic);
    },
    getNewRequest(data, hasClinic = true) {
      const newRequest = {
        id: data.id,
        caseNumber: data.caseNumber,
        instance: data.instance,
        level: data.level,
        hasInsurance: data.hasInsurance,
        hasRecentReports: data.hasRecentReports,
        patient: data.patient,
        createdAt: data.createdAt,
        specialty: data.specialty,
        pathology: data.pathology,
        medicalProcedure: data.medicalProcedure,
        clinic: hasClinic ? data.clinic : null,
        doctor: data.doctor,
        doctors: data.doctors,
        requestStage: data.requestStage,
        status: data.status,
        healthManager: data.healthManager,
        documents: [...data.documents],
        notes: data.notes,
        appointments: data.appointments,
        owner: data.owner,
        accepted: data.accepted,
        collaborators: data.collaborators,
        invitations: data.invitations,
      };

      return newRequest;
    },
    handleDeleteRequest(id) {
      this.$bvModal.hide('delete-request');

      if (this.showDetail) {
        this.hideDetailView();
      }

      for (let i = 0; i < this.requestList.length; i += 1) {
        if (this.requestList[i].id === id) {
          this.requestList.splice(i, 1);
          this.handleSearch(this.pagination.currentPage);
          break;
        }
      }
    },
    addData(data) {
      this.requestList.unshift(data);
      const updatedRequestList = this.removeDuplicates(this.requestList[0].documents);
      this.requestList.documents = updatedRequestList;
      this.handleRevertEditedRowRequest();
      this.incrementPagination();
    },
    removeDuplicates(list) {
      return [...new Set(list)];
    },
    handleCopyRequest(data) {
      this.addData(data);
      this.showCopyForm(data);
    },
    incrementPagination() {
      this.pagination.totalItems += 1;
      this.pagination.itemsPerPage += 1;
    },
    handleUpdateRequest(data) {
      for (let i = 0; i < this.requestList.length; i += 1) {
        if (this.requestList[i].id === data.id) {
          const newRequest = {
            id: data.id,
            caseNumber: data.caseNumber,
            appointments: data.appointments,
            clinic: data.clinic,
            createdAt: data.createdAt,
            documents: data.documents,
            level: data.level,
            healthManager: data.healthManager,
            instance: data.instance,
            hasInsurance: data.hasInsurance,
            medicalProcedure: data.medicalProcedure,
            notes: data.notes,
            pathology: data.pathology,
            patient: data.patient,
            hasRecentReports: data.hasRecentReports,
            specialty: data.specialty,
            requestStage: data.requestStage,
            status: data.status,
            owner: data.owner,
            accepted: data.accepted,
            doctor: data.doctor,
            doctors: data.doctors,
            collaborators: data.collaborators,
            invitations: data.invitations,
          };

          this.requestList.splice(i, 1);
          this.requestList.splice(i, 0, newRequest);
          break;
        }
      }

      this.$root.$emit('bv::toggle::collapse', 'update-request-sidebar');
    },
    checkText() {
      return /\d/.test(this.filters.searchText);
    },
  },
  computed: {
    request: {
      get() { return this.$store.state.request.request; },
      set(value) { this.$store.commit('request/setRequest', value); },
    },
    requestList: {
      get() { return this.$store.state.request.request.requestList; },
      set(value) { this.$store.commit('request/setRequestList', value); },
    },
    pagination: {
      get() { return this.$store.state.request.request.pagination; },
      set(value) { this.$store.commit('request/setRequestPagination', value); },
    },

    isDoctor() {
      return this.$store.getters['user/isDoctor'];
    },
    isClinic() {
      return this.$store.getters['user/isClinic'];
    },
    isDoctorOrClinic() {
      return this.isDoctor || this.isClinic;
    },
    isCollaborator() {
      return this.$store.getters['user/isCollaborator'];
    },
    isDoctorRole() {
      return this.$store.getters['user/isDoctorRole'];
    },
  },
  components: {
    RequestFilters: () => import('@/components/organisms/Request/RequestFilters.vue'),
    RequestTable: () => import('@/components/molecules/Tables/RequestTable/RequestTable.vue'),
    TableGroup: () => import('@/components/molecules/Tables/TableGroup/TableGroup.vue'),
    RequestDetail: () => import('@/components/organisms/Request/RequestDetail.vue'),
    UpdateRequest: () => import('@/components/organisms/Request/UpdateRequest.vue'),
    CopyRequest: () => import('@/components/organisms/Request/CopyRequest.vue'),
    DeleteRequest: () => import('@/components/organisms/Request/DeleteRequest.vue'),
  },
};
</script>
<style lang="sass" scoped>
</style>
